<template>
  <div class="content-list-content">
    <CCard
      style="box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); border-radius: revert"
    >
      <CCardHeader class="d-flex justify-content-between align-items-center">
        <h4 class="cpx-15 font-title header-label mb-0">メール設定</h4>
        <button
          v-on:click="gotoDashboard()"
          class="float-right button-back-dashboard"
        >
          戻る
        </button>
      </CCardHeader>
      <CCardBody class="email-setting px-0">
        <!-- son -->
        <b-row class="align-items-center justify-content-center">
          <b-col class="col-auto">
            <div class="title-btn mx-3 mt-0 col-lg-12">
              <label
                v-for="(item, index) in listSetting"
                :key="index"
                class="mr-3 text-nowrap position-relative"
                :class="
                  typeSetting === item.value
                    ? 'btn-tab-sale-active py-2 px-4'
                    : 'btn-tab-sale-inactive py-2 px-4'
                "
                @click="typeSetting = item.value"
                v-b-toggle.SMTP
              >
                {{ item.text }}
              </label>
            </div>
          </b-col>
        </b-row>
        <div v-if="typeSetting === 1">
          <!-- <div class="px-3">
            <b-button class="btn-collapse" v-b-toggle.SMTP variant="secondary"
              >メールアドレス
            </b-button>
          </div> -->
          <div>
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >SMTPメール設定
                </label>
                <!-- <h4 class="mt-3 font-title title-label pl-3">SMTPメール設定</h4> -->
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2" style="margin-top: 0.5rem">
                    <label class="">SMTP Host</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput
                      v-model="SMTP.host"
                      v-bind:class="{ 'error-input': SMTP.hostInvalid }"
                    />
                    <div class="message-error" v-if="SMTP.hostInvalid">
                      {{ feedback.INVALID }}
                    </div>
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">Type of Encryption</label>
                  </CCol>
                  <CCol sm="10">
                    <CInputRadioGroup
                      :checked.sync="SMTP.type"
                      :options="optionsTypeSMTP"
                      :inline="true"
                      class="mb-3"
                    />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2" style="margin-top: 0.5rem">
                    <label class="">SMTP Port</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="SMTP.port" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">SMTP Authentication</label>
                  </CCol>
                  <CCol sm="10">
                    <CInputRadioGroup
                      :checked.sync="SMTP.authentication"
                      :options="optionsAuthenticationSMTP"
                      :inline="true"
                      class="mb-3"
                    />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2" style="margin-top: 0.5rem">
                    <label class="">SMTP Username</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="SMTP.username" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2" style="margin-top: 0.5rem">
                    <label class="">SMTP Password</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="SMTP.password" type="password" />
                  </CCol>
                </CRow>
                <!-- <CRow class="cpx-4">
                  <CCol sm="2" style="margin-top: 0.5rem">
                    <label class="">メールアドレス</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput
                      v-model="SMTP.email"
                      v-bind:class="{
                        'error-input': SMTP.emailInvalid,
                      }"
                    />
                    <div class="message-error" v-if="SMTP.emailInvalid">
                      {{ feedback.INVALID }}
                    </div>
                  </CCol>
                </CRow> -->
                <CRow class="cpx-4">
                  <CCol sm="2" style="margin-top: 0.5rem">
                    <label class="">BCCメールアドレス</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput
                      v-model="SMTP.emailCC"
                      v-bind:class="{ 'error-input': SMTP.emailCCInvalid }"
                    />
                    <div class="message-error" v-if="SMTP.emailCCInvalid">
                      {{ feedback.INVALID }}
                    </div>
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoSMTP()">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === 2">
          <!-- <div class="px-3">
            <b-button
              class="btn-collapse"
              v-b-toggle.notification-sign
              variant="secondary"
              >登録通知
            </b-button>
          </div> -->
          <div id="notification-sign">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >会員登録があった際の通知設定（管理者に届きます）
                </label>
                <!-- <h4 class="mt-3 font-title pl-3 title-label">
                  会員登録があった際の通知設定（管理者に届きます）
                </h4> -->
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="notificationSign.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="notificationSign.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="notificationSign.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail(0)">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === 3">
          <!-- <div class="px-3">
            <b-button
              class="btn-collapse"
              v-b-toggle.regis-auto-reply
              variant="secondary"
              >登録自動返信
            </b-button>
          </div> -->
          <div id="regis-auto-reply">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >新規登録したユーザーに送る登録後の自動返信メール
                </label>
                <!-- <h4 class="mt-3 font-title pl-3 title-label"> -->
                <!-- 新規登録したユーザーに送る登録後の自動返信メール
                </h4> -->
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="regisAutoReply.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="regisAutoReply.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="regisAutoReply.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail(1)">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <!-- <div class="mt-3">
          <b-button
            class="btn-collapse"
            v-b-toggle.purchase-notification
            variant="secondary"
            >購入通知
          </b-button>
          <div id="purchase-notification" class="mt-2">
            <CRow>
              <CCol sm="12">
                <h4 class="mt-3 pb-3 font-title">
                  購入があった際の通知設定（管理者に届きます）
                </h4>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="purchaseNotification.email" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="purchaseNotification.subject" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea
                      v-model="purchaseNotification.content"
                      rows="10"
                    />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="12" style="text-align: center">
                    <CButton class="btn btn-success" @click="saveInfoMail(2)">
                      更新する
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div> -->
        <!-- <div class="mt-3">
          <b-button
            class="btn-collapse"
            v-b-toggle.purchase-auto-reply
            variant="secondary"
            >購入自動返信
          </b-button>
          <div id="purchase-auto-reply" class="mt-2">
            <CRow>
              <CCol sm="12">
                <h4 class="mt-3 pb-3 font-title">
                  販売の際に新規登録したユーザーに送る登録後の自動返信メール
                </h4>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="purchaseAutoReply.email" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="purchaseAutoReply.subject" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="purchaseAutoReply.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="12" style="text-align: center">
                    <CButton class="btn btn-success" @click="saveInfoMail(3)">
                      更新する
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div> -->
        <!-- <div class="mt-3">
          <b-button
            class="btn-collapse"
            v-b-toggle.after-card-payment
            variant="secondary"
            >カード決済後
          </b-button>
          <div id="after-card-payment" class="mt-2">
            <CRow>
              <CCol sm="12">
                <h4 class="mt-3 pb-3 font-title">
                  お客様に送るカード決済後のメール（販売）
                </h4>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterCardPayment.email" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterCardPayment.subject" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="afterCardPayment.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="12" style="text-align: center">
                    <CButton class="btn btn-success" @click="saveInfoMail(4)">
                      更新する
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div> -->
        <!-- <div class="mt-3">
          <b-button
            class="btn-collapse"
            v-b-toggle.after-apply-bank
            variant="secondary"
            >銀行申込後
          </b-button>
          <div id="after-apply-bank" class="mt-2">
            <CRow>
              <CCol sm="12">
                <h4 class="mt-3 pb-3 font-title">
                  振込申込後に送るお客様宛メール（販売）
                </h4>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterApplyBank.email" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterApplyBank.subject" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="afterApplyBank.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="12" style="text-align: center">
                    <CButton class="btn btn-success" @click="saveInfoMail(5)">
                      更新する
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div> -->
        <!-- <div class="mt-3">
          <b-button
            class="btn-collapse"
            v-b-toggle.after-confirm-transfer
            variant="secondary"
            >振込確認後
          </b-button>
          <div id="after-confirm-transfer" class="mt-2">
            <CRow>
              <CCol sm="12">
                <h4 class="mt-3 pb-3 font-title">
                  振込確認後に送るお客様宛メール（販売）
                </h4>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterConfirmTransfer.email" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterConfirmTransfer.subject" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea
                      v-model="afterConfirmTransfer.content"
                      rows="10"
                    />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="12" style="text-align: center">
                    <CButton class="btn btn-success" @click="saveInfoMail(6)">
                      更新する
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div> -->
        <!-- <div class="mt-3">
          <b-button
            class="btn-collapse"
            v-b-toggle.after-subscribing
            variant="secondary"
            >定期購読申込後
          </b-button>
          <div id="after-subscribing" class="mt-2">
            <CRow>
              <CCol sm="12">
                <h4 class="mt-3 pb-3 font-title">
                  お客様に送る定期購読申込後のメール（販売）
                </h4>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterSubscribing.email" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterSubscribing.subject" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="afterSubscribing.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="12" style="text-align: center">
                    <CButton class="btn btn-success" @click="saveInfoMail(7)">
                      更新する
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div> -->
        <div v-if="typeSetting === 4">
          <!-- <div class="px-3">
            <b-button
              class="btn-collapse"
              v-b-toggle.after-cancel-subscription
              variant="secondary"
              >定期購読解約後
            </b-button>
          </div> -->
          <div id="after-cancel-subscription">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >解約者宛に送る定期購読解約後のメール（販売）
                </label>
                <!-- <h4 class="mt-3 font-title pl-3 title-label">
                  解約者宛に送る定期購読解約後のメール（販売）
                </h4> -->
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterCancelSubscription.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterCancelSubscription.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea
                      v-model="afterCancelSubscription.content"
                      rows="10"
                    />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail(8)">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === 5">
          <!-- <div class="px-3">
            <b-button
              class="btn-collapse"
              v-b-toggle.after-withdrawal
              variant="secondary"
              >退会後
            </b-button>
          </div> -->
          <div id="after-withdrawal">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >退会したユーザーに送るメール
                </label>
                <!-- <h4 class="mt-3 font-title pl-3 title-label">
                  ユーザーが会員サイトを退会した際に通知メール（管理者に届きます）
                </h4> -->
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterWithdrawal.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="afterWithdrawal.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="afterWithdrawal.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail(9)">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === 7">
          <!-- <div class="px-3">
            <b-button
              class="btn-collapse"
              v-b-toggle.password-change-completed
              variant="secondary"
              >パスワード変更完了
            </b-button>
          </div> -->
          <div id="password-change-completed">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >ユーザーのパスワード変更を通知するメール
                </label>
                <!-- <h4 class="mt-3 font-title pl-3 title-label">
                  ユーザーのパスワード変更を通知するメール
                </h4> -->
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="passwordChangeCompleted.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="passwordChangeCompleted.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea
                      v-model="passwordChangeCompleted.content"
                      rows="10"
                    />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail(10)">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === 6">
          <div id="password-change-completed">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >ユーザーがパスワードの変更を要求すると、電子メールが送信されます。
                </label>
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="passwordRequestChange.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="passwordRequestChange.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea
                      v-model="passwordRequestChange.content"
                      rows="10"
                    />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail(11)">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <!-- <div class="mt-3">
          <b-button
            class="btn-collapse"
            v-b-toggle.buy-content
            variant="secondary"
            >著者に基づくメール設定
          </b-button>
          <div id="buy-content" class="mt-2">
            <CRow>
              <CCol sm="12">
                <h4 class="mt-3 pb-3 font-title">著者に基づくメール設定</h4>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>自動返信メール設定</label>
                  </CCol>
                  <CCol sm="10">
                    <label
                      class="custom-ListLabel mb-3"
                      v-bind:class="{
                        checked: index === buyContent.typeDisplay,
                      }"
                      v-on:click="clickTypeDisplay(index, $event)"
                      v-for="(item, index) in listLabelDisplay"
                      :key="index"
                    >
                      {{ item.text }}
                    </label>
                  </CCol>
                  <CCol sm="2">
                    <label>メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="buyContent.email" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="buyContent.subject" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="2">
                    <label>本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="buyContent.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="ml-2">
                  <CCol sm="12" style="text-align: center">
                    <CButton class="btn btn-success" @click="saveInfoMail(10)">
                      更新する
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div> -->
        <div v-if="typeSetting === 8">
          <!-- <div class="px-3">
            <b-button
              class="btn-collapse"
              v-b-toggle.check-email
              variant="secondary"
              >テスト配信
            </b-button>
          </div> -->
          <div id="check-email">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >メール配信が正常かどうか以下よりチェック
                </label>
                <!-- <h4 class="mt-3 font-title pl-3 title-label">
                  メール配信が正常かどうか以下よりチェック
                </h4> -->
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class=""
                      >宛先 <span class="required">(必須)</span></label
                    >
                  </CCol>
                  <CCol sm="10">
                    <CInput
                      v-model="checkEmail.email"
                      v-bind:class="{
                        'error-input':
                          checkEmail.emailInvalid || checkEmail.emailRequired,
                      }"
                    />
                    <div class="message-error" v-if="checkEmail.emailInvalid">
                      {{ feedback.INVALID }}
                    </div>
                    <div class="message-error" v-if="checkEmail.emailRequired">
                      {{ feedback.REQUIRED }}
                    </div>
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class=""
                      >宛名 <span class="required">(必須)</span></label
                    >
                  </CCol>
                  <CCol sm="10">
                    <CInput
                      v-model="checkEmail.nameSender"
                      v-bind:class="{
                        'error-input': checkEmail.nameSenderRequired,
                      }"
                    />
                    <div
                      class="message-error"
                      v-if="checkEmail.nameSenderRequired"
                    >
                      {{ feedback.REQUIRED }}
                    </div>
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class=""
                      >件名 <span class="required">(必須)</span></label
                    >
                  </CCol>
                  <CCol sm="10">
                    <CInput
                      v-model="checkEmail.subject"
                      v-bind:class="{
                        'error-input': checkEmail.subjectRequired,
                      }"
                    />
                    <div
                      class="message-error"
                      v-if="checkEmail.subjectRequired"
                    >
                      {{ feedback.REQUIRED }}
                    </div>
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class=""
                      >本文 <span class="required">(必須)</span></label
                    >
                  </CCol>
                  <CCol sm="10">
                    <CTextarea
                      v-model="checkEmail.content"
                      v-bind:class="{
                        'error-input': checkEmail.contentRequired,
                      }"
                      rows="10"
                    />
                    <div
                      class="message-error"
                      v-if="checkEmail.contentRequired"
                    >
                      {{ feedback.REQUIRED }}
                    </div>
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button
                      class="btn-save"
                      @click="testSendMail()"
                      :disabled="loadingSendMail"
                    >
                      <b-spinner v-if="loadingSendMail" small></b-spinner>
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === 9">
          <div id="regis-auto-reply">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >新規登録したユーザーに送る登録後の自動返信メール
                </label>
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="regisWithoutPass.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="regisWithoutPass.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="regisWithoutPass.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail(1)">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === Constants.EMAIL_TYPE['external_payment_approval_check_to_user']">
          <div id="regis-auto-reply">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >外部決済の管理者による手動承認のお知らせメール
                </label>
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="externalPaymentApprovalCheck.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="externalPaymentApprovalCheck.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="externalPaymentApprovalCheck.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail()">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
        <div v-if="typeSetting === Constants.EMAIL_TYPE['external_payment_approved_to_user']">
          <div id="regis-auto-reply">
            <CRow>
              <CCol sm="12">
                <label class="col-sm-12 font-weight-bold cp-label"
                  >外部決済承認済みのお知らせメール
                </label>
                <CRow class="cpx-4 pt-3">
                  <CCol sm="2">
                    <label class="">メールの送信者名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="externalPaymentAccepted.email" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">件名</label>
                  </CCol>
                  <CCol sm="10">
                    <CInput v-model="externalPaymentAccepted.subject" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="2">
                    <label class="">本文</label>
                  </CCol>
                  <CCol sm="10">
                    <CTextarea v-model="externalPaymentAccepted.content" rows="10" />
                  </CCol>
                </CRow>
                <CRow class="cpx-4">
                  <CCol sm="12" style="text-align: center">
                    <button class="btn-save" @click="saveInfoMail()">
                      更新する
                    </button>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </div>
        </div>
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { Constants } from "../../utils/constants";
import { FeedBack } from "@/utils/feedback.js";
export default {
  name: "CreateUser",
  data() {
    return {
      //son
      Constants: Constants,
      typeSetting: 1,
      listSetting: [
        { value: 1, text: "アドレス設定" },
        { value: 2, text: "登録通知" },
        { value: 3, text: "登録自動返信" },
        { value: 9, text: "簡易登録自動返信" },
        { value: 4, text: "定期購読解約後" },
        { value: 5, text: "退会後" },
        { value: 6, text: "パスワード変更URL" },
        { value: 7, text: "パスワード変更完了" },
        {
          value: Constants.EMAIL_TYPE['external_payment_approval_check_to_user'],
          text: "外部決済手動承認受付",
        },
        {
          value: Constants.EMAIL_TYPE['external_payment_approved_to_user'],
          text: "外部決済承認完了",
        },
        { value: 8, text: "テスト配信" },
      ],
      shop_id: localStorage.getItem(Constants.SHOP_ID),
      feedback: FeedBack,
      SMTP: {
        host: "",
        hostInvalid: false,
        type: "",
        port: "",
        authentication: "",
        username: "",
        password: "",
        email: "",
        emailInvalid: false,
        emailCC: "",
        emailCCInvalid: false,
      },
      optionsTypeSMTP: [
        {
          value: "none",
          label: "none",
        },
        {
          value: "ssl",
          label: "SSL",
        },
        {
          value: "tls",
          label: "TLS",
        },
      ],
      optionsAuthenticationSMTP: [
        {
          value: "0",
          label: "none",
        },
        {
          value: "1",
          label: "SSL",
        },
      ],
      notificationSign: {
        email: "",
        subject: "",
        content: "",
      },
      regisAutoReply: {
        email: "",
        subject: "",
        content: "",
      },
      purchaseNotification: {
        email: "",
        subject: "",
        content: "",
      },
      purchaseAutoReply: {
        email: "",
        subject: "",
        content: "",
      },
      afterCardPayment: {
        email: "",
        subject: "",
        content: "",
      },
      afterApplyBank: {
        email: "",
        subject: "",
        content: "",
      },
      afterConfirmTransfer: {
        email: "",
        subject: "",
        content: "",
      },
      afterSubscribing: {
        email: "",
        subject: "",
        content: "",
      },
      afterCancelSubscription: {
        email: "",
        subject: "",
        content: "",
      },
      afterWithdrawal: {
        email: "",
        subject: "",
        content: "",
      },
      passwordChangeCompleted: {
        email: "",
        subject: "",
        content: "",
      },
      passwordRequestChange: {
        email: "",
        subject: "",
        content: "",
      },
      regisWithoutPass: {
        email: "",
        subject: "",
        content: "",
      },
      externalPaymentApprovalCheck: {
        email: "",
        subject: "",
        content: "",
      },
      externalPaymentAccepted: {
        email: "",
        subject: "",
        content: "",
      },
      checkEmail: {
        email: "",
        emailRequired: false,
        emailInvalid: false,
        nameSender: "",
        nameSenderRequired: false,
        subject: "",
        subjectRequired: false,
        content: "",
        contentRequired: false,
      },
      loadingSendMail: false,
      // listLabelDisplay: [
      //   { value: 0, text: "販売者に届く購入通知" },
      //   { value: 1, text: "ユーザーに届く購入通知" },
      //   { value: 2, text: "お客様に送るカード決済後のメール" },
      //   { value: 3, text: "お客様に送る銀行振込申し込み後のメール" },
      //   { value: 4, text: "振込確認後に送るメール" },
      //   { value: 5, text: "お客様に送る定期購読申し込み後のメール" },
      // ],
      // buyContent: {
      //   email: "",
      //   subject: "",
      //   content: "",
      //   typeDisplay: 0,
      // },
    };
  },
  created() {
    this.getListSettingMail(this.shop_id);
    this.getShopByIdForAdmin(this.shop_id);
  },
  computed: {
    ...mapGetters([
      "message",
      "success",
      "error",
      "listSettingMail",
      "detailShop",
    ]),
  },
  watch: {
    detailShop() {
      this.SMTP.host = this.detailShop.SMTP_host;
      this.SMTP.type = this.detailShop.type_encryption;
      this.SMTP.port = this.detailShop.smtp_port;
      this.SMTP.authentication = this.detailShop.smtp_authen + "";
      this.SMTP.username = this.detailShop.smtp_username;
      this.SMTP.password = this.detailShop.smtp_password;
      // this.SMTP.email = this.detailShop.email_addr;
      this.SMTP.emailCC = this.detailShop.cc_email_addr;
    },
    listSettingMail() {
      this.listSettingMail.forEach((element) => {
        switch (element.mail_type) {
          case 0:
            this.notificationSign.email = element.name_sender;
            this.notificationSign.subject = element.mail_subject;
            this.notificationSign.content = element.text;
            break;
          case 1:
            this.regisAutoReply.email = element.name_sender;
            this.regisAutoReply.subject = element.mail_subject;
            this.regisAutoReply.content = element.text;
            break;
          case 2:
            this.purchaseNotification.email = element.name_sender;
            this.purchaseNotification.subject = element.mail_subject;
            this.purchaseNotification.content = element.text;
            break;
          case 3:
            this.purchaseAutoReply.email = element.name_sender;
            this.purchaseAutoReply.subject = element.mail_subject;
            this.purchaseAutoReply.content = element.text;
            break;
          case 4:
            this.afterCardPayment.email = element.name_sender;
            this.afterCardPayment.subject = element.mail_subject;
            this.afterCardPayment.content = element.text;
            break;
          case 5:
            this.afterApplyBank.email = element.name_sender;
            this.afterApplyBank.subject = element.mail_subject;
            this.afterApplyBank.content = element.text;
            break;
          case 6:
            this.afterConfirmTransfer.email = element.name_sender;
            this.afterConfirmTransfer.subject = element.mail_subject;
            this.afterConfirmTransfer.content = element.text;
            break;
          case 7:
            this.afterSubscribing.email = element.name_sender;
            this.afterSubscribing.subject = element.mail_subject;
            this.afterSubscribing.content = element.text;
            break;
          case 8:
            this.afterCancelSubscription.email = element.name_sender;
            this.afterCancelSubscription.subject = element.mail_subject;
            this.afterCancelSubscription.content = element.text;
            break;
          case 9:
            this.afterWithdrawal.email = element.name_sender;
            this.afterWithdrawal.subject = element.mail_subject;
            this.afterWithdrawal.content = element.text;
            break;
          case 10:
            this.passwordChangeCompleted.email = element.name_sender;
            this.passwordChangeCompleted.subject = element.mail_subject;
            this.passwordChangeCompleted.content = element.text;
            break;
          case 14:
            this.passwordRequestChange.email = element.name_sender;
            this.passwordRequestChange.subject = element.mail_subject;
            this.passwordRequestChange.content = element.text;
            break;
          case 15:
            this.regisWithoutPass.email = element.name_sender;
            this.regisWithoutPass.subject = element.mail_subject;
            this.regisWithoutPass.content = element.text;
            break;
          case Constants.EMAIL_TYPE['external_payment_approval_check_to_user'] :
            this.externalPaymentApprovalCheck.email = element.name_sender;
            this.externalPaymentApprovalCheck.subject = element.mail_subject;
            this.externalPaymentApprovalCheck.content = element.text;
            break;
          case Constants.EMAIL_TYPE['external_payment_approved_to_user'] :
            this.externalPaymentAccepted.email = element.name_sender;
            this.externalPaymentAccepted.subject = element.mail_subject;
            this.externalPaymentAccepted.content = element.text;
            break;
        }
      });
    },
    success() {
      if (this.success) {
        this.$toasted.success(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["success", false]);
        this.loadingSendMail = false;
      }
    },
    error() {
      if (this.error) {
        this.$toasted.error(this.message);
        this.$store.commit("set", ["message", ""]);
        this.$store.commit("set", ["error", false]);
        this.loadingSendMail = false;
      }
    },
    "SMTP.host"() {
      this.SMTP.hostInvalid = false;
    },
    // "SMTP.email"() {
    //   this.SMTP.emailInvalid = false;
    // },
    "SMTP.emailCC"() {
      this.SMTP.emailCCInvalid = false;
    },
    "checkEmail.email"() {
      this.checkEmail.emailRequired = false;
      this.checkEmail.emailInvalid = false;
    },
    "checkEmail.nameSender"() {
      this.checkEmail.nameSenderRequired = false;
    },
    "checkEmail.subject"() {
      this.checkEmail.subjectRequired = false;
    },
    "checkEmail.content"() {
      this.checkEmail.contentRequired = false;
    },
  },
  methods: {
    ...mapActions({ getShopByIdForAdmin: "getShopByIdForAdmin" }),
    ...mapActions({ getListSettingMail: "getListSettingMail" }),
    ...mapActions({ saveSMTP: "saveSMTP" }),
    ...mapActions({ saveMail: "saveMail" }),
    ...mapActions({ testMail: "testMail" }),
    // clickTypeDisplay(index) {
    //   if (this.buyContent.typeDisplay !== index) {
    //     this.buyContent.typeDisplay = index;
    //   }
    // },
    saveInfoSMTP() {
      let error = false;
      // if (this.SMTP.host && !this.SMTP.host.match(Constants.REGEX_TEXT)) {
      //   this.SMTP.hostInvalid = true;
      //   error = true;
      // }
      // if (!this.SMTP.email) {
      //   this.SMTP.emailInvalid = true;
      //   error = true;
      // }
      if (!this.SMTP.emailCC) {
        this.SMTP.emailCCInvalid = true;
        error = true;
      } else {
        let emailCC = this.SMTP.emailCC.replace(" ", "");
        let arr_email_cc = emailCC.split(",");
        arr_email_cc.forEach((value) => {
          if (!value.match(Constants.REGEX_EMAIL)) {
            this.SMTP.emailCCInvalid = true;
            error = true;
          }
        });
      }
      if (!error) {
        const formSMTP = {
          shop_id: this.shop_id,
          SMTP_host: this.SMTP.host,
          type_encryption: this.SMTP.type,
          smtp_port: this.SMTP.port,
          smtp_authen: this.SMTP.authentication,
          smtp_username: this.SMTP.username,
          smtp_password: this.SMTP.password,
          // email_addr: this.SMTP.email,
          cc_email_addr: this.SMTP.emailCC,
        };
        this.saveSMTP(formSMTP);
      }
    },
    saveInfoMail() {
      // let mail;
      // switch (mail_type) {
      //   case 0:
      //     mail = this.notificationSign;
      //     break;
      //   case 1:
      //     mail = this.regisAutoReply;
      //     break;
      //   case 2:
      //     mail = this.purchaseNotification;
      //     break;
      //   case 3:
      //     mail = this.purchaseAutoReply;
      //     break;
      //   case 4:
      //     mail = this.afterCardPayment;
      //     break;
      //   case 5:
      //     mail = this.afterApplyBank;
      //     break;
      //   case 6:
      //     mail = this.afterConfirmTransfer;
      //     break;
      //   case 7:
      //     mail = this.afterSubscribing;
      //     break;
      //   case 8:
      //     mail = this.afterCancelSubscription;
      //     break;
      //   case 9:
      //     mail = this.afterWithdrawal;
      //     break;
      //   case 10:
      //     mail = this.passwordChangeCompleted;
      //     break;
      // }
      const formMail = {
        shop_id: this.shop_id,
        mails: [
          {
            mail_type: 0,
            name_sender: this.notificationSign.email,
            mail_subject: this.notificationSign.subject,
            text: this.notificationSign.content,
          },
          {
            mail_type: 1,
            name_sender: this.regisAutoReply.email,
            mail_subject: this.regisAutoReply.subject,
            text: this.regisAutoReply.content,
          },
          {
            mail_type: 2,
            name_sender: this.purchaseNotification.email,
            mail_subject: this.purchaseNotification.subject,
            text: this.purchaseNotification.content,
          },
          {
            mail_type: 3,
            name_sender: this.purchaseAutoReply.email,
            mail_subject: this.purchaseAutoReply.subject,
            text: this.purchaseAutoReply.content,
          },
          {
            mail_type: 4,
            name_sender: this.afterCardPayment.email,
            mail_subject: this.afterCardPayment.subject,
            text: this.afterCardPayment.content,
          },
          {
            mail_type: 5,
            name_sender: this.afterApplyBank.email,
            mail_subject: this.afterApplyBank.subject,
            text: this.afterApplyBank.content,
          },
          {
            mail_type: 6,
            name_sender: this.afterConfirmTransfer.email,
            mail_subject: this.afterConfirmTransfer.subject,
            text: this.afterConfirmTransfer.content,
          },
          {
            mail_type: 7,
            name_sender: this.afterSubscribing.email,
            mail_subject: this.afterSubscribing.subject,
            text: this.afterSubscribing.content,
          },
          {
            mail_type: 8,
            name_sender: this.afterCancelSubscription.email,
            mail_subject: this.afterCancelSubscription.subject,
            text: this.afterCancelSubscription.content,
          },
          {
            mail_type: 9,
            name_sender: this.afterWithdrawal.email,
            mail_subject: this.afterWithdrawal.subject,
            text: this.afterWithdrawal.content,
          },
          {
            mail_type: 10,
            name_sender: this.passwordChangeCompleted.email,
            mail_subject: this.passwordChangeCompleted.subject,
            text: this.passwordChangeCompleted.content,
          },
          {
            mail_type: 14,
            name_sender: this.passwordRequestChange.email,
            mail_subject: this.passwordRequestChange.subject,
            text: this.passwordRequestChange.content,
          },
          {
            mail_type: 15,
            name_sender: this.regisWithoutPass.email,
            mail_subject: this.regisWithoutPass.subject,
            text: this.regisWithoutPass.content,
          },
          {
            mail_type: Constants.EMAIL_TYPE['external_payment_approval_check_to_user'],
            name_sender: this.externalPaymentApprovalCheck.email,
            mail_subject: this.externalPaymentApprovalCheck.subject,
            text: this.externalPaymentApprovalCheck.content,
          },
          {
            mail_type: Constants.EMAIL_TYPE['external_payment_approved_to_user'],
            name_sender: this.externalPaymentAccepted.email,
            mail_subject: this.externalPaymentAccepted.subject,
            text: this.externalPaymentAccepted.content,
          },
        ],
      };
      this.saveMail(formMail);
    },
    testSendMail() {
      let error = false;
      if (this.checkEmail.email === "") {
        error = true;
        this.checkEmail.emailRequired = true;
      }
      if (
        this.checkEmail.email &&
        !this.checkEmail.email.match(Constants.REGEX_EMAIL)
      ) {
        error = true;
        this.checkEmail.emailInvalid = true;
      }
      if (this.checkEmail.nameSender === "") {
        error = true;
        this.checkEmail.nameSenderRequired = true;
      }
      if (this.checkEmail.subject === "") {
        error = true;
        this.checkEmail.subjectRequired = true;
      }
      if (this.checkEmail.content === "") {
        error = true;
        this.checkEmail.contentRequired = true;
      }
      if (!error) {
        this.loadingSendMail = true;
        const formMail = {
          shop_id: this.shop_id,
          receiver_addr_mail_delivery: this.checkEmail.email,
          receiver_name_mail_delivery: this.checkEmail.nameSender,
          subject_mail_delivery: this.checkEmail.subject,
          text_mail_delivery: this.checkEmail.content,
        };
        this.testMail(formMail);
      }
    },
    gotoDashboard() {
      this.$router.push({
        name: this.$route.params.shopId
          ? "Dashboard Admin"
          : "Dashboard Admin domain",
      });
    },
  },
};
</script>
<style lang="scss">
.label-required {
  width: 50px;
  text-align: center;
  background-color: red;
  color: #fff;
  border-radius: 10px;
  margin-left: 5px;
}
.btn-collapse:after {
  display: inline-block;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;

  border-bottom: 10px solid black;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "";
  transform: rotate(180deg);
  transition: all linear 0.25s;
  float: right;
  position: absolute;
  top: 30%;
  right: 10px;
}
.btn-collapse.collapsed:after {
  transform: rotate(0deg);
}
button.btn-collapse {
  width: 500px;
  text-align: left;
  position: relative;
}
.error-input {
  border: solid 1px #e55353 !important;
}
.message-error {
  color: #e55353;
}
.required {
  background: red;
  color: #fff;
  margin-left: 10px;
}
.title-label {
  background: #fff;
  width: 100%;
  align-items: center;
  display: flex;
  height: 40px;
}
.row-input input {
  margin-top: 0px !important;
}
.form-group {
  padding-right: 1.5rem !important;
}
//son
.btn-tab-sale-active {
  background: #ff644e;
  border: #ff644e;
  color: #fff;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding: 7px 2px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 140px;
}
.btn-tab-sale-inactive {
  color: #636f83;
  border-color: #636f83;
  display: inline-block;
  font-weight: 400;
  color: #3c4b64;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #636f83;
  padding: 7px 2px;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 3px;
  min-width: 140px;
}
.btn-tab-sale-active:after {
  position: absolute;
  bottom: -9px;
  left: 50%;
  margin-left: -10px;
  content: "";
  display: block;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 9px solid #ff644e;
}
@media screen and (max-width: 1300px) {
  .title-btn label {
    font-size: 0.8em;
    min-width: 50px;
  }
}
</style>
